import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Data, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';
import { Breadcrumb } from '../models/breadcrumb/breadcrumb';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  // Subject emitting the breadcrumb hierarchy
  private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);

  // Observable exposing the breadcrumb hierarchy
  readonly breadcrumbs$ = this._breadcrumbs$.asObservable();

  constructor(private router: Router) {
    this.router.events.pipe(
      // Filter the NavigationEnd events as the breadcrumb is updated only when the route reaches its end
      filter((event) => event instanceof NavigationEnd),
      //startWith(undefined)
    ).subscribe(event => {
      // Construct the breadcrumb hierarchy
      const root = this.router.routerState.snapshot.root;
      const breadcrumbs: Breadcrumb[] = [];
      this.addBreadcrumb(root, [], breadcrumbs);
      
      // Emit the new hierarchy
      this._breadcrumbs$.next(breadcrumbs);
    });
  }

  private addBreadcrumb(route: ActivatedRouteSnapshot | null, parentUrl: string[], breadcrumbs: Breadcrumb[]) {
    if (route) {
      // Construct the route URL
      const routeUrl = parentUrl.concat(route.url.map(url => url.path));

      if (breadcrumbs.find(b => b.label === 'Home') === undefined) {
        breadcrumbs.push({
          label: 'Home',
          url: ''
        });
      }

      if (route.data.parentBreadcrumb) {
        const parentBreadcrumb = this.getParentBreadcrumb(route.data);
        breadcrumbs.push({
          label: parentBreadcrumb.label,
          url: '/' + parentUrl.join('/') + (parentBreadcrumb.url ? '/' + parentBreadcrumb.url : '')
        });
      }

      // Add an element for the current route part
      if (route.data.breadcrumb) {
        const breadcrumbLabel = this.getLabel(route.data);

        if (breadcrumbs.find(b => b.label === breadcrumbLabel) === undefined) {
          breadcrumbs.push({
            label: breadcrumbLabel,
            url: '/' + routeUrl.join('/')
          });
        }
      }

      // Add another element for the next route part
      this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs);
    }
  }

  private getLabel(data: Data) {
    // The breadcrumb can be defined as a static string or as a function to construct the breadcrumb element out of the route data
    return typeof data.breadcrumb === 'function' ? data.breadcrumb(data) : data.breadcrumb;
  }

  private getParentBreadcrumb(data: Data) {
    return {
      url: typeof data.parentBreadcrumb === 'function' ? data.parentBreadcrumb(data).url : null,
      label: typeof data.parentBreadcrumb === 'function' ? data.parentBreadcrumb(data).label : data.parentBreadcrumb
    };
  }

}
