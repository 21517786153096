<div class="breadcrumb breadcrumb--light">
  <div class="breadcrumb__container">
    <div class="breadcrumb__inner-container">
      <nav role="navigation" class="breadcrumb__nav">
        <ul class="breadcrumbs breadcrumb__list">
          <li class="breadcrumb__item" *ngFor="let breadcrumb of (breadcrumbs$ | async); let $last = last">
            <a [routerLink]="breadcrumb.url" class="breadcrumb__link" *ngIf="!$last">
              {{ breadcrumb.label }}
            </a>
            <span *ngIf="$last"> {{ breadcrumb.label }}</span>
            <i *ngIf="!$last" class="material-icons breadcrumb__separator">navigate_next</i>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>
<div class="breadcrumb__content-buffer"></div>
