import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';

@Pipe({
  name: 'sharePrice'
})
export class SharePricePipe implements PipeTransform {

  transform(value: number) {
    if (!value || isNaN(value)) {
      return '£0.00';
    }

    return `£${formatNumber(value, 'en-GB', '.2-10')}`;
  }
  transformToTwoDigits(value: number) {
    if (!value || isNaN(value)) {
      return '';
    }

    return formatNumber(value, 'en-GB', '.2-2');
  }
  transformtozero(value: number) {
    if (!value || isNaN(value)) {
      return '';
    }

    return formatNumber(value, 'en-GB', '.0-0');
  }
}
