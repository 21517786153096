import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_PAGINATOR_DEFAULT_OPTIONS } from '@angular/material/paginator';
import { MatProgressSpinnerModule, MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS } from '@angular/material/progress-spinner';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MsalModule, MsalService, MsalGuard, MsalBroadcastService, MsalRedirectComponent } from '@azure/msal-angular';
import { AppComponent } from './app.component';
import {
  MY_MAT_DATE_FORMATS, MY_MAT_FORM_FIELD_DEFAULT_OPTIONS, MY_MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MY_MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS, MY_MAT_SNACK_BAR_DEFAULT_OPTIONS, MY_MAT_PAGINATOR_DEFAULT_OPTIONS, MY_MAT_TABS_CONFIG
} from './app.constants';
import { BreadcrumbModule } from './components/breadcrumb/breadcrumb.component';
import { CardCollectionModule } from './components/card-collection/card-collection.component';
import { CardModule } from './components/card/card.component';
import { InvestorCrmSearchModule } from './components/investor-crm-search/investor-crm-search.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { NotAuthorisedComponent } from './components/not-authorised/not-authorised.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { PageHeadingModule } from './components/page-heading/page-heading.component';
import { ProfileComponent } from './components/profile/profile.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { httpInterceptorProviders } from './http-interceptors';
import { MessageService } from './message.service';

const routes: Routes = [
  {
    path: '',
    data: {
      breadcrumb: 'Home'
    },
    component: DashboardComponent,
  },
  {
    path: 'companies',
    data: {
      breadcrumb: 'Companies'
    },
    loadChildren: () => import('./companies/companies.module').then(m => m.CompaniesModule),
    canLoad: [MsalGuard]
  },
 
  {
    path: 'document-generation',
    data: {
      breadcrumb: 'Document Generation'
    },
    loadChildren: () => import('./document-generation/document-generation-dashboard.module').then(m => m.DocumentGenerationDashboardModule),
    canLoad: [MsalGuard]
  },
  {
    path: 'eis',
    data: {
      breadcrumb: 'EIS'
    },
    loadChildren: () => import('./eis/eis.module').then(m => m.EisModule),
    canLoad: [MsalGuard]
  },
  {
    path: 'reports',
    data: {
      breadcrumb: 'Reports'
    },
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
    canLoad: [MsalGuard]
  },
  {
    path: 'partner-reporting',
    data: {
      breadcrumb: 'Partner Reporting'
    },
    loadChildren: () => import('./partner-reporting/partner-reporting.module').then(m => m.PartnerReportingModule),
    canLoad: [MsalGuard]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'customer-service-dashboard',
    data: {
      breadcrumb: 'Customer Service Dashboard'
    },
    loadChildren: () => import('./customer-service-dashboard/customer-service-dashboard.module').then(m => m.CustomerServiceDashboardModule),
    canLoad: [MsalGuard]
  },
  {
    path: 'fund-admin-dashboard',
    data: {
      breadcrumb: 'Fund Admin Dashboard'
    },
    loadChildren: () => import('./fund-administration/fund-administration.module').then(m => m.FundAdministrationModule),
    canLoad: [MsalGuard]
  },
  {
    path: 'notauthorised',
    component: NotAuthorisedComponent,
  },
  {
    path: 'auth-response',
    component: MsalRedirectComponent
  },
  {
    path: '**',
    component: NotFoundComponent,
  }
];

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    NavMenuComponent,
    NotAuthorisedComponent,
    NotFoundComponent,
    ProfileComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      // Although we have multiple modules we can still preload them so there isn't a delay when navigating
      preloadingStrategy: PreloadAllModules
    }),
    HttpClientModule,
    CardModule,
    CardCollectionModule,
    BrowserModule.withServerTransition({ appId: 'fund-accounting' }),
    BreadcrumbModule,
    InvestorCrmSearchModule,
    PageHeadingModule,
    FormsModule,
    ReactiveFormsModule,
    MsalModule,
    BrowserAnimationsModule,
    MatMomentDateModule,
    MatProgressSpinnerModule
  ],
  entryComponents: [
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_MAT_DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: MY_MAT_MOMENT_DATE_ADAPTER_OPTIONS },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: MY_MAT_FORM_FIELD_DEFAULT_OPTIONS },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: MY_MAT_SNACK_BAR_DEFAULT_OPTIONS },
    { provide: MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS, useValue: MY_MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS },
    { provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: MY_MAT_PAGINATOR_DEFAULT_OPTIONS },
    { provide: MAT_TABS_CONFIG, useValue: MY_MAT_TABS_CONFIG },
    httpInterceptorProviders,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    MessageService
  ],
  bootstrap: [
    AppComponent,
    MsalRedirectComponent
  ],
  exports: [
    RouterModule
  ]
})

export class AppModule {
  static InjectorInstance: Injector;

  constructor(private injector: Injector) {
    AppModule.InjectorInstance = this.injector;
  }
}
