import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { MSAL_INSTANCE, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalInterceptorConfiguration, ProtectedResourceScopes } from '@azure/msal-angular';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { IAppConfigResponse } from './app/services/app-config.service';
import { BrowserCacheLocation, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

if (environment.production) {
  enableProdMode();
}

function loggerCallback(logLevel: LogLevel, message: string) {
  // console.log("MSAL Angular:", message);
}

fetch('/api/clientsettings')
  .then(response => response.json())
  .then((settings: IAppConfigResponse) => {
    platformBrowserDynamic([
      {
        provide: 'BASE_URL',
        useFactory: getBaseUrl,
        deps: []
      },
      {
        provide: 'API_URL',
        useValue: settings.bopApiEndpoint
      },
      {
        provide: MSAL_INSTANCE,
        useValue: new PublicClientApplication(
          {
            auth: {
              clientId: settings.azureAd.applicationId,
              authority: settings.azureAd.authority,
              redirectUri: `${window.location.origin}/auth-response`,
              postLogoutRedirectUri: window.location.origin,
              navigateToLoginRequestUrl: true,
            },
            cache: {
              cacheLocation: BrowserCacheLocation.LocalStorage,
              storeAuthStateInCookie: true,
            },
            system: {
              allowRedirectInIframe: true,
              loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Info,
                piiLoggingEnabled: false
              }
            }
          })
      },
      {
        provide: MSAL_GUARD_CONFIG,
        useValue: {
          interactionType: InteractionType.Redirect,
          authRequest: {
            redirectStartPage: window.location.href,
            scopes: [`api://${settings.azureAd.applicationId}/access_via_approle_assignments`]
          }
        } as MsalGuardConfiguration
      },
      {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useValue: {
          interactionType: InteractionType.Redirect,
          protectedResourceMap: new Map<string, Array<string | ProtectedResourceScopes> | null>(
            [
              [
                'https://graph.microsoft.com/v1.0/me',
                [
                  'user.read'
                ]
              ],
              [
                `${window.location.origin}/api`,
                [
                  `api://${settings.azureAd.applicationId}/access_via_approle_assignments`
                ]
              ],
              [
                `${settings.bopApiEndpoint}api/`,
                [
                  `api://${settings.azureAd.applicationId}/access_via_approle_assignments`
                ]
              ]
            ],
          )
        } as MsalInterceptorConfiguration
      }
    ])
      .bootstrapModule(AppModule)
      .catch(err => console.log(err));
  });
