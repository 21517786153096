import { Component, ViewEncapsulation } from '@angular/core';
import { Card } from '../models/card/card';
import { Router } from '@angular/router';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent {

  cards: Array<Card> = [];

  constructor(private router: Router) {
    this.cards.push(new Card('eis', 'EIS Share Register', 'Manage and view digital EIS share registers'));
    this.cards.push(new Card('companies', 'Share Classes', 'View information about companies, including share price history'));
    this.cards.push(new Card('customer-service-dashboard', 'Customer Service Dashboard', 'For customer service operations'));
    this.cards.push(new Card('partner-reporting', 'Partner Reporting', 'Manage and generate external partner reports'));
    this.cards.push(new Card('reports', 'Reports', 'View investment product reports'));
    this.cards.push(new Card('fund-admin-dashboard', 'Fund Administration', 'Share register management'));
    this.cards.push(new Card('document-generation', 'Document Generation', 'Produce and view document generations'));
  }


}
