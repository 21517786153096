import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  profile: any;
  msalAccount: AccountInfo | null;

  constructor(private authService: MsalService) {
  }

  ngOnInit() {
    this.getMsalAccount();
  }

  getMsalAccount() {
    this.msalAccount = this.authService.instance.getActiveAccount();
  }
}
