<a class="card" [routerLink]="[card.buttonLink]" data-equalizer-watch="cards" aria-hidden="false">
  <div class="card-section card__text-container">

    <div class="card__heading-and-text-container">
      <h4 class="card__heading card__heading--large">{{card.title}}</h4>
      <p class="card__text">{{card.description}}</p>
    </div>

    <div class="card__details-container">
      <p class="card__detail card__detail--arrow">
        <i class="material-icons">arrow_forward</i>
      </p>
    </div>
  </div>
</a>
