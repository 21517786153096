export interface IInvestorLookupApiResponse {
  id: string;
  hasShareholderId: boolean;
  shareholderId: string;
  fullName: string;
  crmId: string;
  crmLink: string;
  title: string;
  firstName: string;
  legalName: string;
  lastName: string;
  middleName: string;
  salutation: string;
  dateOfBirth: Date;
  nationalInsuranceNumber: string;
  emailAddress: string;
  telephone: string;
  errorMsg:string;
  address: IContactAddressLookupApiResponse;
  communicationPreferences: boolean;
  portfolios: IContactPortfolioLookupApiResponse[]
  displayName: string;
  searchBy: number;
}

export interface IContactPortfolioLookupApiResponse {
  id: string;
  reference:string;
  stateCode: number;
  crmPortfolioId: string;
  subscriptions:IPortfolioSubscriptionLookupApiResponse[]
}

export interface IPortfolioSubscriptionLookupApiResponse {
  id: string;
  subscriptionReference: string;
  stateCode:number;
}


export interface IContactAddressLookupApiResponse {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  city: string;
  county: string;
  postcode: string;
  oneLineAddress: string;
}
