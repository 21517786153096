import { Injectable } from '@angular/core';
import { Message } from './models/message/message';
import { Subject } from 'rxjs';

@Injectable(
  { providedIn: 'root' }
)
export class MessageService {
  messages: Message[] = [];
  public messageSubject: Subject<Message> = new Subject<Message>();
  constructor() { }

  add(message: Message) {
    this.messages.push(message);
    this.messageSubject.next(message);
  }

  clear() {
    this.messages = [];
  }
}
