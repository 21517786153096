import { Component, Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-page-heading',
  templateUrl: './page-heading.component.html',
  styleUrls: ['./page-heading.component.css']
})
export class PageHeadingComponent {
  @Input() pageHeader!: string;
  constructor() { }

}

@NgModule({
  declarations: [PageHeadingComponent],
  imports: [CommonModule, RouterModule],
  exports: [CommonModule, PageHeadingComponent, FormsModule]
})
export class PageHeadingModule { }
