<div class="top-spacer"></div>
<header class="top-bar__sticky-container">
  <!-- Small screen -->
  <div class="sticky title-bar__sticky" style="width:100%">
    <div class="title-bar" data-responsive-toggle="responsive-menu">
      <a id="top-bar-home-link" [routerLink]="''" class="title-bar__home-link">
        <img class="title-bar__home-link-img" src="assets/images/logos/icon-only.svg" />
      </a>
      <button class="menu-icon title-bar__hamburger" type="button" data-toggle="responsive-menu"></button>
    </div>
  </div>

  <!-- Large screen or dropdown from hamburger trigger -->
  <!-- //sticky fluid  -->
  <div id="responsive-menu" class="top-bar__outer-container">
    <div class="top-bar__container">
      <div class="top-bar__inner-container">
        <div class="top-bar">

          <div class="top-bar-left top-bar__home-link-container">
            <a id="top-bar-home-link" [routerLink]="''" class="top-bar__home-link hide-for-small-only">
              <img class="top-bar__home-link-img" src="assets/images/logos/logo-positive.svg" />
            </a>
          </div>

          <div class="top-bar-right top-bar__right-menu-container">
            <ul class="menu top-bar__menu">

              <li class="top-bar__menu-item" *ngFor="let menuItem of menuItems">
                <a class="top-bar__menu-link " [routerLink]="[menuItem.path]">
                  <span>{{menuItem.title}}</span>
                  <i class="material-icons top-bar__menu-link-icon hide-for-medium">chevron_right</i>
                </a>
              </li>

            </ul>
          </div>

        </div>
      </div>
    </div>
  </div>
</header>
