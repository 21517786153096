import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IInvestorLookupApiResponse } from '../models/investor/IInvestorLookupApiResponse';
import { Investor } from '../models/investor/Investor';
import { IValidateCrmIdApiResponse } from '../models/investor/IValidateCrmIdApiResponse';
import { IUpdateCrmIdForm } from '../eis/investors/update-crm-id/update-crm-id-form-model';
import { IUpdateCrmIdApiResponse } from '../eis/investors/update-crm-id/update-crm-id-api-response.model';
import { InvestorPortfolio } from '../models/investor/InvestorPortfolio';
import { ViewInvestorPortfolio } from '../models/investor/ViewInvestorPortfolio';
import { IUpdateAdviserDetailsForm } from '../eis/investors/update-adviser-details/update-adviser-details-form-model';
import { IUpdatePortfolioApiResponse } from '../eis/investors/update-adviser-details/update-adviser-details-api-response.model';
import { IViewInvestorPortfolioApiResponse } from '../models/investor/IViewInvestorApiResponse';
import { SearchBy } from '../enums/search-by-enum';

@Injectable({
  providedIn: 'root'
})
export class InvestorsService {
  constructor(private http: HttpClient,
    @Inject('API_URL')
    private apiUrl: string) {
  }

  fetchInvestor(id: string): Observable<IInvestorLookupApiResponse> {
    return this.http.get<IInvestorLookupApiResponse>(`/api/investors/${id}`);
  }

  searchInvestors(searchBy: SearchBy, searchTerm: string): Observable<IInvestorLookupApiResponse[]> {
    return this.http.get<IInvestorLookupApiResponse[]>(`${this.apiUrl}api/investors?searchBy=${searchBy}&searchTerm=${searchTerm}`);
  }

  searchEisInvestors(searchTerm: string): Observable<Investor[]> {
    return this.http.get<Investor[]>(`api/investors/eis?searchTerm=${searchTerm}`);
  }

  // Used by EIS investor details screen
  getInvestor(shareholderId: string): Observable<IInvestorLookupApiResponse> {
    return this.http.get<IInvestorLookupApiResponse>(`api/investors/${shareholderId}`);
  }

  validateCrmId(crmId: string): Observable<IValidateCrmIdApiResponse> {
    return this.http.get<IValidateCrmIdApiResponse>(`api/investors/${crmId}/validate`);
  }

  updateInvestor(shareholderId: string, model: IUpdateCrmIdForm): Observable<IUpdateCrmIdApiResponse> {
    return this.http.patch<IUpdateCrmIdApiResponse>(
      `/api/investors/${shareholderId}`,
      {
        crmId: model.crmId
      }
    );
  }

  getPortfolios(crmId: string): Observable<IViewInvestorPortfolioApiResponse[]> {
    return this.http.get<IViewInvestorPortfolioApiResponse[]>(`${this.apiUrl}api/investors/${crmId}/portfolios`);
  }

  getPortfoliosSimple(crmId: string): Observable<IViewInvestorPortfolioApiResponse[]> {
    return this.http.get<IViewInvestorPortfolioApiResponse[]>(`${this.apiUrl}api/investors/${crmId}/portfolios/simple`);
  }

  getPortfoliosWithHoldings(crmId: string): Observable<IViewInvestorPortfolioApiResponse[]> {
    return this.http.get<IViewInvestorPortfolioApiResponse[]>(`${this.apiUrl}api/investors/${crmId}/portfolios/PortfoliosWithHoldings`);
  }

  getPortfolio(crmId: string, portfolioId: number) {
    return this.http.get<IViewInvestorPortfolioApiResponse>(`${this.apiUrl}api/investors/${crmId}/portfolios/${portfolioId}`);
  }

  getPortfolioDetails(crmId: string, portfolioId: number) {
    return this.http.get<IViewInvestorPortfolioApiResponse>(`${this.apiUrl}api/investors/${crmId}/portfolios/${portfolioId}/simple`);
  }

  getEisPortfolios(shareholderId: string): Observable<InvestorPortfolio[]> {
    return this.http.get<InvestorPortfolio[]>(`api/investors/${shareholderId}/portfolios`);
  }

  getEisPortfolio(shareholderId: string, portfolioId: number) {
    return this.http.get<ViewInvestorPortfolio>(`api/investors/${shareholderId}/portfolios/${portfolioId}`);
  }

  getPortfolioSimple(portfolioId: number) {
    return this.http.get<ViewInvestorPortfolio>(`${this.apiUrl}api/portfolios/${portfolioId}`);
  }

  updatePortfolioAdviser(shareholderId: string, portfolioId: number, model: IUpdateAdviserDetailsForm):
    Observable<IUpdatePortfolioApiResponse> {
    return this.http.patch<IUpdatePortfolioApiResponse>(
      `/api/investors/${shareholderId}/portfolios/${portfolioId}`,
      {
        adviser: {
          crmId: model.crmId
        }
      }
    );
  }
}
