import { Component, HostBinding, Input } from '@angular/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { Card } from '../../models/card/card';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {
  @HostBinding('[class.app-card]')
  displayHostClass = true;

  @Input() card: Card;  

  constructor() {
    this.card = new Card('', '', '');
  }
}

@NgModule({
  declarations: [CardComponent],
  imports: [CommonModule, RouterModule],
  exports: [CommonModule, CardComponent, FormsModule]
})
export class CardModule { }
