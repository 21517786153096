import { Component, OnInit } from '@angular/core';

declare var $: any;

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  { path: '/companies', title: 'Companies', icon: '', class: '' },
  { path: '/eis', title: 'EIS', icon: '', class: '' },
  { path: '/partner-reporting', title: 'Partner Reporting', icon: '', class: '' },
  { path: '/reports', title: 'Reports', icon: '', class: '' },
];

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {
  menuItems: any[] = [];

  constructor() {

  }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    $(document).foundation();
    // Foundation framework bug - mobile header is not sticking
    const foundation = window['Foundation' as any] as any;
    $(window).on('sticky.zf.unstuckfrom:bottom', function(e: Event) {
      if (foundation.MediaQuery.atLeast('medium')) {
        $(e.target).removeClass('is-anchored is-at-bottom').attr('style', '');
      }
    });
  }


}
