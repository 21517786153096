import { Component } from '@angular/core';

@Component({
  selector: 'app-not-authorised',
  templateUrl: './not-authorised.component.html',
  styleUrls: ['./not-authorised.component.scss']
})
export class NotAuthorisedComponent {

  constructor() {
  }

}
