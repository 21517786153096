<form [formGroup]="investorSearchForm" class="dashboard-view__add-exit-form" [id]="investorSearchFormId">
  <fieldset class="search">
    <div class="search__input-wrapper">
      <input type="text" placeholder="Search..." formControlName="searchInput" autocomplete="off"
             class="dashboard-view__add-exit-form-input search__input" (keyup.enter)="searchInvestor($event)"
             id="search-input">
      <select id="search__searchBy" formControlName="searchBy" (change)="clearSearchResults()" [hidden]="selectionHidden">
        <option *ngFor="let option of searchBy" [value]="mode ?? option.id">
          {{option.name}}
        </option>
      </select>
      <button class="button primary search__button" type="button" [disabled]="searchInProgress"
              (click)="searchInvestor($event)" id="dashboard-search-for-investor-button">
        <span *ngIf="!searchInProgress">Search</span>
        <span *ngIf="searchInProgress" class="search__spinner">
          <mat-spinner [diameter]="20"></mat-spinner>
        </span>
      </button>
      <div *ngIf="searchInProgress" class="search__input-spinner">
        <mat-spinner [diameter]="20"></mat-spinner>
      </div>
      <ul class="search__result-view"
          *ngIf="(investorSearchResults.length || noResultsFound) && !searchInProgress">
        <li class="search__result-view-list-wrapper">
          <ul class="search__result-view-list">
            <ng-container *ngFor="let searchItem of investorSearchResults.slice(0, searchResultDisplayLimit); let i = index">
              <ng-container *ngIf="!searchInProgress && !noResultsFound">
                <li class="search__result-view-list-item" (click)="onSearchItemSelection(searchItem)">
                    <span [innerHTML]="searchItem.displayName">
                    </span>
                </li>
              </ng-container>
            </ng-container>
            <li *ngIf="noResultsFound"
                class="search__result-view-list-item search__result-view-list-item--no-records-found">
              {{responsemessage}}
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <section *ngIf="errorMessage" class="search_error-container">
      <div class="info-alert">
        <div class="info-alert__icon">
          <i class="material-icons">close</i>
        </div>
        <div class="info-alert__content">
          <p>{{errorMessage}}</p>
        </div>
      </div>
    </section>
  </fieldset>
</form>
