import { Component, Input, HostBinding } from '@angular/core';
import { NgModule } from '@angular/core';
import { Card } from '../../models/card/card';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CardModule } from '../card/card.component';

@Component({
  selector: 'app-card-collection',
  templateUrl: './card-collection.component.html',
  styleUrls: ['./card-collection.component.scss']
})
export class CardCollectionComponent {
  @HostBinding('class.contained-card-collection') get Class() { return true; }

  @Input() cards: Array<Card> = [];

  constructor() { }
}

@NgModule({
  declarations: [CardCollectionComponent],
  imports: [CommonModule, RouterModule, CardModule],
  exports: [CommonModule, CardCollectionComponent, FormsModule]
})
export class CardCollectionModule { }
