import { NgModule } from '@angular/core';
import { SharePricePipe } from './share-price.pipe';

@NgModule({
    declarations: [
        SharePricePipe
    ],
    exports: [
        SharePricePipe
    ]
})
export class PipesModule {

}
